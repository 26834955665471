import { Dropdown, Popover, Carousel } from 'bootstrap';
import GLightbox from 'glightbox';

const lightbox = GLightbox({});

document.querySelectorAll('[data-bs-toggle="popover"]').forEach((popover) => {
  const popoverContent = document.getElementById(`${popover.id}-content`);
  new Popover(popover, { html: true, sanitize: false, content: popoverContent.innerHTML });
});

const contactPopover = document.getElementById('bamper-contact-popover');
if (contactPopover) {
  document.querySelector('body').addEventListener('click', (e) => {
    const popovers = document.querySelectorAll('.popover.show');
    for (const p of popovers) {
      const selector = `[aria-describedby=${p.id}]`;
      if (!e.target.closest(selector)) {
        const trigger = document.querySelector(selector);
        if (trigger) Popover.getInstance(trigger).hide();
      }
    }
  });
}

const searchBox = document.getElementById('search-box');
const catsParent = document.getElementById('cats-parent');
const catsMenu = document.getElementById('cats-menu-toggler');
if (searchBox && catsParent && catsMenu) {
  const dd = document.getElementById('search-dropdown');
  // dd.style.width = `${catsParent.offsetWidth - catsMenu.offsetWidth - 6}px`;
  if (catsParent.offsetWidth >= 768)
    dd.style.width = `${catsParent.offsetWidth - catsMenu.offsetWidth - 6}px`;
  else
    dd.style.width = '100vw';
}

const catsDropdown = document.getElementById('cats-dropdown');
if (catsDropdown) {
  const catsMenu = document.getElementById('cats-menu');
  catsDropdown.addEventListener('show.bs.dropdown', (event) => {
    const catsParent = document.getElementById('cats-parent');
    catsMenu.style.width =
      catsParent.offsetWidth >= 768 ? `${Math.floor(catsParent.offsetWidth * 0.4)}px` : `${catsParent.offsetWidth}px`;
  });
}

document.querySelectorAll('[data-bamper-btn="cats"]').forEach((btn) => {
  btn.addEventListener('click', (e) => {
    document.querySelectorAll('[data-bamper-btn="cats"]').forEach((btn) => {
      btn.classList.remove('active', 'fw-bold');
    });
    btn.classList.add('active', 'fw-bold');
  });
});

document.querySelectorAll('[data-bamper-collapse="cats-cat-subs"]').forEach((cl) => {
  cl.addEventListener('show.bs.collapse', (e) => {
    document.getElementById(`${cl.id}-icon`).classList.add('bamper-rotate-180');
  });
  cl.addEventListener('hide.bs.collapse', (e) => {
    document.getElementById(`${cl.id}-icon`).classList.remove('bamper-rotate-180');
  });
});

document.querySelectorAll('[data-bamper-collapse="bamper-nav-submenu"]').forEach((cl) => {
  cl.addEventListener('show.bs.collapse', (e) => {
    document.getElementById(`${cl.id}-icon`).classList.add('bamper-rotate-180');
  });
  cl.addEventListener('hide.bs.collapse', (e) => {
    document.getElementById(`${cl.id}-icon`).classList.remove('bamper-rotate-180');
  });
});

document.querySelectorAll('div.bamper-submenu-dropdown').forEach((cl) => {
  const iEl = document.getElementById(`${cl.id}-icon`);
  cl.addEventListener('show.bs.dropdown', (e) => {
    if (iEl) iEl.classList.add('bamper-rotate-180');
    document.getElementById(`${cl.id}-bullet`).classList.remove('d-none');
  });
  cl.addEventListener('hide.bs.dropdown', (e) => {
    if (iEl) iEl.classList.remove('bamper-rotate-180');
    document.getElementById(`${cl.id}-bullet`).classList.add('d-none');
  });
});

document.querySelectorAll('[data-bamper-collapse="card-full-desc"]').forEach((cl) => {
  cl.addEventListener('show.bs.collapse', (e) => {
    const t1 = document.getElementById('card-show-desc');
    t1.classList.remove('d-flex');
    t1.classList.add('d-none');
    const t2 = document.getElementById('card-hide-desc');
    t2.classList.remove('d-none');
    t2.classList.add('d-flex');
  });
  cl.addEventListener('hide.bs.collapse', (e) => {
    const t1 = document.getElementById('card-show-desc');
    t1.classList.remove('d-none');
    t1.classList.add('d-flex');
    const t2 = document.getElementById('card-hide-desc');
    t2.classList.remove('d-flex');
    t2.classList.add('d-none');
  });
});

const carouselElem = document.getElementById('bamper-item-carousel');
if (carouselElem) {
  const carousel = new Carousel(carouselElem, { interval: false });
  const thumbs = document.getElementById('bamper-item-thumbs');
  if (carousel && thumbs) {
    for (let i = 0; i < thumbs.children.length; i++) {
      thumbs.children[i].addEventListener('click', (e) => {
        carousel.to(i);
      });
    }
  }
}

const thumbLeft = document.getElementById('bamper-item-thumb-left');
const thumbRight = document.getElementById('bamper-item-thumb-right');
if (thumbLeft && thumbRight) {
  const itemThumbs = document.getElementById('bamper-item-thumbs');
  thumbLeft.addEventListener('click', (e) => itemThumbs.scrollBy(-130, 0));
  thumbRight.addEventListener('click', (e) => itemThumbs.scrollBy(130, 0));
}
